import {
  createFeatureSelector,
  createSelector,
  ActionReducerMap
} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

export interface State {
  router: fromRouter.RouterReducerState<any>;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer
};

export const selectRouter = createFeatureSelector<
  State,
  fromRouter.RouterReducerState<any>
>('router');

export const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl
} = fromRouter.getSelectors(selectRouter);

export const getRouterUrl = createSelector(
  selectRouter,
  router => router && router.state.url
);

export const selectRouterChildren = createSelector(
  selectRouter,
  router => router && router.state.root.children
);

export const selectChannelId = createSelector(
  selectRouterChildren,
  children => {
    const channel =
      children && children.find(child => child.outlet === 'channel');
    return channel && channel.params.channelId;
  }
);

export const selectVideoId = createSelector(selectRouterChildren, children => {
  const video = children && children.find(child => child.outlet === 'video');
  return video && video.params.videoId;
});

export const selectEmbedVideoId = selectRouteParam('videoId');

export const selectRelatedChannelId = createSelector(selectRouterChildren, children => {
  const video = children && children.find(child => child.outlet === 'video');
  return video && video.params.relatedChannelId;
});

export const selectCatalogId = selectRouteParam('catalog');

export const selectCode = selectRouteParam('code');
export const selectCode2 = selectRouteParam('code2');
export const selectEmail = selectRouteParam('email');

export const selectQueryModal = selectQueryParam('m');
export const selectQueryAuto = selectQueryParam('a');
export const selectQueryTime = selectQueryParam('t');

export const selectQueryCoupon = selectQueryParam('coupon');
export const selectQueryPlan = selectQueryParam('plan');
