import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

export const fadeAnimation = trigger('fade', [
  state('void', style({ opacity: 0 })),
  transition('void <=> *', animate(250))
]);

export const fadeInAnimation = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition('void => *', animate(250))
]);

export const fadeOutAnimation = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition('void <= *', animate(250))
]);

export const slideLeftAnimation = trigger('slideRight', [
  state('void', style({ opacity: 0, transform: 'translateX(2rem)' })),
  transition('void <=> *', animate(250))
]);

export const slideRightAnimation = trigger('slideRight', [
  state('void', style({ opacity: 0, transform: 'translateX(-2rem)' })),
  transition('void <=> *', animate(250))
]);

export const revealAnimation = trigger('reveal', [
  state('active', style({ height: '*' })),
  state('inactive', style({ height: 0 })),
  transition('active => inactive', animate('250ms ease-out')),
  transition('inactive => active', animate('250ms ease-in'))
]);
