import { AnnouncementComponent } from './announcement/announcement.component';
import { DeviceInstallComponent } from './device-install/device-install.component';
import { FeaturesComponent } from './features/features.component';
import { HeroComponent } from './hero/hero.component';
import { HeroActionsComponent } from './hero-actions/hero-actions.component';
import { IconBlocksComponent } from './icon-blocks/icon-blocks.component';
import { MarketingComponent } from './marketing/marketing.component';
import { MetricsComponent } from './metrics/metrics.component';
import { PageChannelComponent } from './page-channel/page-channel.component';
import { PlatformsComponent } from './platforms/platforms.component';
import { WatchAnywhereComponent } from './watch-anywhere/watch-anywhere.component';

export const components = [
  AnnouncementComponent,
  DeviceInstallComponent,
  FeaturesComponent,
  HeroComponent,
  HeroActionsComponent,
  IconBlocksComponent,
  MarketingComponent,
  MetricsComponent,
  PageChannelComponent,
  PlatformsComponent,
  WatchAnywhereComponent
];

export * from './announcement/announcement.component';
export * from './device-install/device-install.component';
export * from './features/features.component';
export * from './hero/hero.component';
export * from './hero-actions/hero-actions.component';
export * from './icon-blocks/icon-blocks.component';
export * from './marketing/marketing.component';
export * from './metrics/metrics.component';
export * from './page-channel/page-channel.component';
export * from './platforms/platforms.component';
export * from './watch-anywhere/watch-anywhere.component';
