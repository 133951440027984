import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as fromCatalogs from '../../actions/catalogs/catalogs.actions';
import * as fromChannels from '../../actions/channels/channels.actions';
import * as fromVideos from '../../actions/videos/videos.actions';
import { CatalogsService } from '../../../../api/services';

@Injectable()
export class CatalogsEffects {
  loadCatalogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogs.loadCatalogs),
      mergeMap(() =>
        this.catalogsService.list().pipe(
          map(catalogs =>
            fromCatalogs.loadCatalogsSuccess({ catalogs })
          ),
          catchError(error => of(fromCatalogs.loadCatalogsFailure({ error })))
        )
      )
    )
  );

  loadCatalogsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogs.loadCatalogsSuccess),
      map(({ catalogs }) => fromCatalogs.normalizeCatalogs({ catalogs }))
    )
  );

  normalizeCatalogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogs.normalizeCatalogs),
      map(({ catalogs }) => {
        const channels = catalogs
          .reduce(
            (acc, cur) => [
              ...acc,
              ...cur['channels']],
            []
          )
          .filter(channel => channel && channel.id);
        return fromChannels.normalizeChannels({ channels });
      })
    )
  );

  // loadChannel$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.loadChannel),
  //     mergeMap(({ id }) =>
  //       this.channelsService.view(id).pipe(
  //         map(channel => fromActions.loadChannelSuccess({ channel })),
  //         catchError(error => of(fromActions.loadChannelFailure({ id, error })))
  //       )
  //     )
  //   )
  // );

  // loadChannelsSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.loadChannelsSuccess),
  //     map(({ channels }) => fromActions.normalizeChannels({ channels }))
  //   )
  // );

  // loadChannelSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.loadChannelSuccess),
  //     map(({ channel }) => {
  //       const channels = [channel];

  //       return fromActions.normalizeChannels({ channels });
  //     })
  //   )
  // );

  // normalizeChannels$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.normalizeChannels),
  //     map(({ channels }) => {
  //       const videos = channels
  //         .reduce(
  //           (acc, cur) => [
  //             ...acc,
  //             cur['trailer_video'],
  //             ...cur['videos']],
  //           []
  //         )
  //         .filter(video => video && video.id);
  //       return fromVideos.normalizeVideos({ videos });
  //     })
  //   )
  // );

  constructor(
    private actions$: Actions,
    private catalogsService: CatalogsService
  ) { }
}
