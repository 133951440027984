import { AnchorService } from './anchor/anchor.service';
import { ClickService } from './click/click.service';
import { CustomTextService } from './custom-text/custom-text.service';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';
import { JwplayerService } from './jwplayer/jwplayer.service';
import { KeyboardService } from './keyboard/keyboard.service';
import { ScrollService } from './scroll/scroll.service';
import { StripeService } from './stripe/stripe.service';

export const services = [
  AnchorService,
  ClickService,
  CustomTextService,
  GoogleAnalyticsService,
  JwplayerService,
  KeyboardService,
  ScrollService,
  StripeService
];
