import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromCoreGuards from './core/guards';
import * as fromCorePages from './core/pages';
import * as fromHomePages from './modules/home/pages';

const routes: Routes = [
  {
    path: '',
    canActivate: [
      fromCoreGuards.AccountGuard,
      fromCoreGuards.AuthGuard,
      fromCoreGuards.HomeContentGuard,
      fromCoreGuards.LiveGuard,
      fromCoreGuards.CatalogsGuard,
    ],
    component: fromHomePages.HomeComponent,
    data: { navOverlap: true }
  },
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: 'contact',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: 'faq',
    pathMatch: 'full',
    redirectTo: 'support/faq'
  },
  {
    path: 'sign-in',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: 'activate',
    pathMatch: 'full',
    redirectTo: 'user/activate-tv'
  },
    {
    path: 'subscribe',
    pathMatch: 'full',
    redirectTo: 'user/subscribe'
  },
  {
    path: 'channels',
    canActivate: [fromCoreGuards.AccountGuard, fromCoreGuards.AuthGuard, fromCoreGuards.LiveGuard],
    loadChildren: () =>
      import('./modules/channels/channels.module').then(
        mod => mod.ChannelsModule
      )
  },
  {
    path: 'embed',
    canActivate: [fromCoreGuards.AccountGuard, fromCoreGuards.AuthGuard],
    data: { nav: false, footer: false },
    loadChildren: () =>
      import('./modules/embed/embed.module').then(
        mod => mod.EmbedModule
      )
  },
  {
    path: 'search',
    canActivate: [fromCoreGuards.AccountGuard, fromCoreGuards.AuthGuard, fromCoreGuards.LiveGuard],
    loadChildren: () =>
      import('./modules/search/search.module').then(mod => mod.SearchModule)
  },
  {
    path: 'support',
    canActivate: [fromCoreGuards.AccountGuard, fromCoreGuards.AuthGuard, fromCoreGuards.LiveGuard],
    loadChildren: () =>
      import('./modules/support/support.module').then(mod => mod.SupportModule)
  },
  {
    path: 'user',
    canActivate: [fromCoreGuards.AccountGuard, fromCoreGuards.AuthGuard],
    loadChildren: () =>
      import('./modules/user/user.module').then(mod => mod.UserModule)
  },
  {
    path: 'coming-soon',
    canActivate: [fromCoreGuards.AccountGuard],
    component: fromCorePages.ComingSoonComponent,
    data: { nav: false, footer: false, title: 'Coming Soon' }
  },
  {
    path: '**',
    canActivate: [fromCoreGuards.AccountGuard],
    component: fromCorePages.PageNotFoundComponent,
    data: { title: 'Page not found' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
