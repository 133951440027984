import { ContinueComponent } from './continue/continue.component';
import { FooterComponent } from './footer/footer.component';
import { InstallPromptBarComponent } from './install-prompt-bar/install-prompt-bar.component';
import { LockedComponent } from './locked/locked.component';
import { ModalsComponent } from './modals/modals.component';
import { NavComponent } from './nav/nav.component';
import { PromotionComponent } from './promotion/promotion.component';
import { ResourceLinksComponent } from './resource-links/resource-links.component';
import { StreamingNowComponent } from './streaming-now/streaming-now.component';
import { ToastsComponent } from './toasts/toasts.component';

export const components = [
  ContinueComponent,
  FooterComponent,
  InstallPromptBarComponent,
  LockedComponent,
  ModalsComponent,
  NavComponent,
  PromotionComponent,
  ResourceLinksComponent,
  StreamingNowComponent,
  ToastsComponent,
];
