import { createAction, props } from '@ngrx/store';

export const normalizeCatalogs = createAction(
  '[Catalogs] Normalize Catalogs',
  props<{ catalogs: any[] }>()
);

export const loadCatalogs = createAction('[Catalogs] Load Catalogs');

export const loadCatalogsSuccess = createAction(
  '[Catalogs] Load Catalogs Success',
  props<{ catalogs: any[] }>()
);

export const loadCatalogsFailure = createAction(
  '[Catalogs] Load Catalogs Failure',
  props<{ error: any }>()
);

// export const loadCatalog = createAction(
//   '[Catalogs] Load Catalog',
//   props<{ id: string }>()
// );

// export const loadCatalogSuccess = createAction(
//   '[Catalogs] Load Catalog Success',
//   props<{ catalog: any }>()
// );

// export const loadCatalogFailure = createAction(
//   '[Catalogs] Load Catalog Failure',
//   props<{ id: string; error: any }>()
// );
