import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { pluckIds, unwrap } from '../../../../util/code.util';
import * as fromActions from '../../actions/catalogs/catalogs.actions';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  loaded: boolean;
  loading: boolean;
  error: any;
  index: string[];
  entityLoading: { [id: string]: boolean };
  entityError: { [id: string]: any };
}

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: undefined,
  index: [],
  entityLoading: {},
  entityError: {}
});

const catalogsReducer = createReducer(
  initialState,
  on(fromActions.normalizeCatalogs, (state, { catalogs }) => {
    const normalized = catalogs.map(catalog => ({
      ...catalog,
      channels: pluckIds(catalog, 'channels')
    }));
    return adapter.upsertMany(normalized, state);
  }),
  on(fromActions.loadCatalogs, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: undefined
  })),
  on(fromActions.loadCatalogsSuccess, (state, { catalogs }) => {
    const index = catalogs.map(catalog => catalog.id);
    return {
      ...state,
      index,
      loaded: true,
      loading: false,
      error: undefined
    };
  }),
  on(fromActions.loadCatalogsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  })),
  // on(fromActions.loadCatalog, (state, { id }) => {
  //   const entityLoading = {
  //     ...state.entityLoading,
  //     [id]: true
  //   };
  //   const entityError = {
  //     ...state.entityError,
  //     [id]: undefined
  //   };
  //   return {
  //     ...state,
  //     entityLoading,
  //     entityError
  //   };
  // }),
  // on(fromActions.loadCatalogSuccess, (state, { catalog }) => {
  //   const { id } = catalog;
  //   const entityLoading = {
  //     ...state.entityLoading,
  //     [id]: false
  //   };
  //   const entityError = {
  //     ...state.entityError,
  //     [id]: undefined
  //   };
  //   return {
  //     ...state,
  //     entityLoading,
  //     entityError
  //   };
  // }),
  // on(fromActions.loadCatalogFailure, (state, { id, error }) => {
  //   const entityLoading = {
  //     ...state.entityLoading,
  //     [id]: false
  //   };
  //   const entityError = {
  //     ...state.entityError,
  //     [id]: error
  //   };
  //   return {
  //     ...state,
  //     entityLoading,
  //     entityError
  //   };
  // })
);

export function reducer(state: State | undefined, action: Action) {
  return catalogsReducer(state, action);
}
