import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Renderer2,
  Input
} from '@angular/core';

import { channelLiveVideoCount, channelNewVideoCount } from 'src/app/util/code.util';

@Component({
  selector: 'odm-channel-item',
  templateUrl: './channel-item.component.html',
  styleUrls: ['./channel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelItemComponent implements OnInit {
  @Input() channel: any;
  tileHovered = false;

  constructor(
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  get newCount(): number {
    return channelNewVideoCount(this.channel);
  }

  get liveCount(): number {
    return channelLiveVideoCount(this.channel);
  }

  getChannelImage(url?: string): string {
    if (url && url.endsWith('series-temp-thumb.png')) {
      return 'assets/img/channel-thumb-missing.png'
    }
    return url;
  }

  toggleHover() {
    this.tileHovered = !this.tileHovered;
  }
}
