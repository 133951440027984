import { AccountLogoComponent } from './account-logo/account-logo.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ChannelItemComponent } from './channel-item/channel-item.component';
import { IntlTelInputComponent } from './intl-tel-input/intl-tel-input.component';
import { FavoriteButtonComponent } from './favorite-button/favorite-button.component';
import { ProgressComponent } from './progress/progress.component';
import { ProgressIndeterminateComponent } from './progress-indeterminate/progress-indeterminate.component';
import { StopwatchComponent } from './stopwatch/stopwatch.component';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';
import { VideoItemComponent } from './video-item/video-item.component';

export const components = [
  AccountLogoComponent,
  CarouselComponent,
  ChannelItemComponent,
  IntlTelInputComponent,
  FavoriteButtonComponent,
  ProgressComponent,
  ProgressIndeterminateComponent,
  StopwatchComponent,
  VideoCarouselComponent,
  VideoItemComponent
];

export * from './carousel/carousel.component';
export * from './channel-item/channel-item.component';
export * from './intl-tel-input/intl-tel-input.component';
export * from './favorite-button/favorite-button.component';
export * from './progress/progress.component';
export * from './progress-indeterminate/progress-indeterminate.component';
export * from './stopwatch/stopwatch.component';
export * from './video-carousel/video-carousel.component';
export * from './video-item/video-item.component';
