import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, finalize, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class CatalogsGuard implements CanActivate {
  constructor(private store: Store<any>) {}

  canActivate(): Observable<boolean> {
    let loading: boolean;
    return this.store.pipe(
      select(fromStore.selectAccount),
      filter(account => account),
      switchMap(() => {
        return this.store.pipe(
          select(fromStore.selectCatalogsLoaded),
          tap(loaded => {
            if (!loaded) {
              this.store.dispatch(fromStore.loadCatalogs());
              this.store.dispatch(fromStore.increaseLoadingCount());
              loading = true;
            }
          }),
          finalize(() => {
            if (loading) {
              this.store.dispatch(fromStore.decreaseLoadingCount());
            }
          }),
          filter(loaded => loaded)
        );
      })
    );
  }
}
