import { AccountGuard } from './account.guard';
import { AuthGuard } from './auth.guard';
import { CatalogsGuard } from './catalogs.guard';
import { ChannelResourcesGuard } from './channel-resources.guard';
import { ChannelGuard } from './channel.guard';
import { ChannelsGuard } from './channels.guard';
import { EmbedVideoGuard } from './embed-video.guard';
import { HomeContentGuard } from './home-content.guard';
// import { JwplayerGuard } from './jwplayer.guard';
import { LiveGuard } from './live.guard';
import { MarketingContentGuard } from './marketing-content.guard';
import { StripeGuard } from './stripe.guard';
import { SubscriberContentGuard } from './subscriber-content.guard';
import { UserErrorGuard } from './user-error.guard';
import { UserGuard } from './user.guard';
import { VideoResourcesGuard } from './video-resources.guard';
import { VideoGuard } from './video.guard';

export const guards = [
  AccountGuard,
  AuthGuard,
  CatalogsGuard,
  ChannelResourcesGuard,
  ChannelGuard,
  ChannelsGuard,
  EmbedVideoGuard,
  HomeContentGuard,
  // JwplayerGuard,
  LiveGuard,
  MarketingContentGuard,
  StripeGuard,
  SubscriberContentGuard,
  UserErrorGuard,
  UserGuard,
  VideoResourcesGuard,
  VideoGuard,
];

export * from './account.guard';
export * from './auth.guard';
export * from './catalogs.guard';
export * from './channel-resources.guard';
export * from './channel.guard';
export * from './channels.guard';
export * from './embed-video.guard';
export * from './home-content.guard';
// export * from './jwplayer.guard';
export * from './live.guard';
export * from './marketing-content.guard';
export * from './stripe.guard';
export * from './subscriber-content.guard';
export * from './user-error.guard';
export * from './user.guard';
export * from './video-resources.guard';
export * from './video.guard';
