import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromReducer from '../../reducers/catalogs/catalogs.reducer';
import * as fromRoot from '../../../../store';
import * as fromChannels from '../channels/channels.selectors';
import * as fromVideos from '../videos/videos.selectors';
import * as fromVideoUserData from '../video-user-data/video-user-data.selectors';

export const selectCatalogsState = createSelector(
  fromFeature.selectCoreState,
  state => state.catalogs
);

const { selectEntities } = fromReducer.adapter.getSelectors();

export const selectCatalogsEntities = createSelector(
  selectCatalogsState,
  selectEntities
);

const selectCatalogsIndex = createSelector(
  selectCatalogsState,
  state => state.index
);

const selectCatalogsEntityLoading = createSelector(
  selectCatalogsState,
  state => state.entityLoading
);

const selectCatalogsEntityError = createSelector(
  selectCatalogsState,
  state => state.entityError
);

export const selectCatalogsLoaded = createSelector(
  selectCatalogsState,
  state => state.loaded
);

export const selectCatalogsLoading = createSelector(
  selectCatalogsState,
  state => state.loading
);

export const selectCatalogsError = createSelector(
  selectCatalogsState,
  state => state.error
);

export const selectCatalog = createSelector(
  selectCatalogsEntities,
  (entities, props) => entities[props.id]
);

export const selectCatalogLoaded = id =>
  createSelector(
    selectCatalogsEntities,
    entities => !!entities[id]
  );

export const selectCatalogLoading = createSelector(
  selectCatalogsEntityLoading,
  (entityLoading, props) => entityLoading[props.id]
);

export const selectCatalogError = createSelector(
  selectCatalogsEntityError,
  (entityError, props) => entityError[props.id]
);

export const denormalizeCatalog = (
  id: string,
  catalogEntities,
  channelEntities,
  videoEntities,
  videoUserDataEntities
) => {
  const catalog = catalogEntities[id];
  return (
    catalog && {
      ...catalog,
      channels: fromChannels.denormalizeChannels(
        catalog.channels,
        channelEntities,
        videoEntities,
        videoUserDataEntities
      )
    }
  );
};

export const denormalizeCatalogs = (
  ids: string[],
  catalogEntities,
  channelEntities,
  videoEntities,
  videoUserDataEntities
) =>
  ids &&
  ids.map(id =>
    denormalizeCatalog(
      id,
      catalogEntities,
      channelEntities,
      videoEntities,
      videoUserDataEntities
    )
  );

export const selectCatalogs = createSelector(
  selectCatalogsIndex,
  selectCatalogsEntities,
  fromChannels.selectChannelsEntities,
  fromVideos.selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeCatalogs
);

export const selectActiveCatalog = createSelector(
  fromRoot.selectCatalogId,
  selectCatalogsEntities,
  fromChannels.selectChannelsEntities,
  fromVideos.selectVideosEntities,
  fromVideoUserData.selectVideoUserDataEntities,
  denormalizeCatalog
);

// export const selectRelatedCatalog = createSelector(
//   fromRoot.selectRelatedCatalogId,
//   selectCatalogsEntities,
//   fromVideos.selectVideosEntities,
//   fromVideoUserData.selectVideoUserDataEntities,
//   denormalizeCatalog
// );
